import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { LayoutActionTypes, useLayoutDispatch } from '~/context/layout.context';
import useIOSViewport from '~/init/use-ios-viewport-height';
import { useCMSPage } from '~/libs/queries/bff';
import { config } from '../../env.config';

const ErrorPage = dynamic(() => import('./root-page/root-page.component'));
const StandardPage = dynamic(() => import('./standard-page/standard-page.component'));

// List of page types from CMS
export const pageTypes: Record<string, React.ComponentType> = {
    P062InternalPage: StandardPage,
    P063ExternalPage: StandardPage,
    // TODO: Ask backend for correct ´contentPage` identifier
    // TODO: re-implement content page
    // contentPage: dynamic(() => import('./content-page/content-page.component')),
    P020HomePage: dynamic(() =>
        import('./root-page/root-page.component').then((module) => {
            return module.LoginPage;
        }),
    ),
    P140NotFoundPage: StandardPage,
    P150ErrorPage: ErrorPage,
    default: StandardPage,
};

export const PageRender = () => {
    const { data: pageContent } = useCMSPage();
    const { type: pageType } = pageContent ?? {};
    const PageType = !pageType ? pageTypes['default'] : pageTypes[pageType] ?? pageTypes['default'];
    const router = useRouter();
    const layoutDispatch = useLayoutDispatch();

    const startFetching = () =>
        layoutDispatch({
            type: LayoutActionTypes.SetLoading,
            payload: true,
        });

    const stopFetching = () =>
        layoutDispatch({
            type: LayoutActionTypes.SetLoading,
            payload: false,
        });

    useEffect(() => {
        router.events.on('routeChangeStart', startFetching);
        router.events.on('routeChangeComplete', stopFetching);
        return () => {
            router.events.off('routeChangeStart', startFetching);
            router.events.off('routeChangeComplete', stopFetching);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Get the real viewport height from buggy iOS browsers
    useIOSViewport();

    return (
        <>
            <Head>
                <title key="title">{pageContent?.metaTitle ?? 'FTZ'}</title>
                <meta name="buildinfo" content={config.NEXT_PUBLIC_BUILD_ID} />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, shrink-to-fit=no"
                    key="viewport"
                />
                {/* Hide website from search engines, maybe change this in future when site is available without login?  */}
                <meta name="googlebot" content="noindex" />
                <meta name="robots" content="noindex" />
            </Head>

            <PageType />
        </>
    );
};
